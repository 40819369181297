import React, { useState } from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { MenuItem, Menu } from 'react-aim-menu';
import statenames from "../data/state_abbrev_to_name.json";
import { USAMapStateSelect } from './USAMap';
// import "../styles/styles.css";
import "../styles/navbar.css";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownItemOpen, setDropdownItemOpen] = useState(null);
  
  return <div className="nav-bar-container">
    <Link className="breaking-update" to="/redistricting-report-card">
      Find gerrymanders on our new Redistricting Report Card!
    </Link>
    <div className="header-bar">
      <Link className="nav-logo" to="/">
        Gerrymandering Project
      </Link>
      <button
        onClick={() => setMenuOpen(!menuOpen)}
        className="nav-menu-button">
        { menuOpen ? 'Close' : 'Menu'}
      </button>
      {
        menuOpen &&
        <div className="nav-menu-dropdown">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <hr />
            <h3>About</h3>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              {<Link to="/team">Team</Link>}
            </li>
            <hr />
            <h3>Maps</h3>
            <li>
              <Link to="/redistricting-report-card">Redistricting Report Card</Link>
            </li>
            <li>
              <Link to="/redistricting-timeline-alert-map">Redistricting Timeline Alert</Link>
            </li>
            <li>
              <Link to="/reforms">Reforms</Link>
            </li>
            <li>
              <Link to="/tests">Gerrymandering tests (old)</Link>
            </li>
            <hr />
            <h3>Resources</h3>
            <li>
              <Link to="/resources">Resources</Link>
            </li>
            <hr />
            <h3>States</h3>
            <li>
              <USAMapStateSelect />
            </li>
            <hr />
            <li>
              <OutboundLink target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLScx9z7g6Kd7wkTYNtsD59XmxFN6ZdRpVa2bQx2UR6XrZiQmJA/viewform?usp=sf_link">
                Submit Feedback
              </OutboundLink>
            </li>
            <hr />
            <h3>Related Projects</h3>
            <li>
              <OutboundLink target="_blank" rel="noopener noreferrer" href="https://electoral-lab.org">
                Electoral Lab
              </OutboundLink>
            </li>
            <li>
              <OutboundLink target="_blank" rel="noopener noreferrer" href="https://representable.org">
                Representable
              </OutboundLink>
            </li>
            <li>
              <OutboundLink target="_blank" rel="noopener noreferrer" href="https://election.princeton.edu">
                PEC
              </OutboundLink>
            </li>
            <li>
              <OutboundLink target="_blank" rel="noopener noreferrer" href="https://openprecincts.org">
                OpenPrecincts
              </OutboundLink>
            </li>
          </ul>
        </div>
      }
      <ul className="nav-buttons">
      <Menu
          onMouseLeave={() => setDropdownItemOpen(null)}>
          <MenuItem onHover={() => setDropdownItemOpen('About')}> 
            <a>About {dropdownItemOpen === 'About' ? '▲' : '▼'}</a>
          
          <ul 
            className="nav-menu-dropdown-item"
            style={{
            display: dropdownItemOpen === 'About' ? 'block' : 'none'
          }}>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/redistricting-report-card-methodology">Methodology</Link>
            </li>
            <li>
              {<Link to="/team">Team</Link>}
            </li>
          </ul>
          </MenuItem>
        </Menu>
        <Menu
          onMouseLeave={() => setDropdownItemOpen(null)}>
          <MenuItem onHover={() => setDropdownItemOpen('Maps')}> 
            <a>Maps {dropdownItemOpen === 'Maps' ? '▲' : '▼'}</a>
          
          <ul 
            className="nav-menu-dropdown-item"
            style={{
            display: dropdownItemOpen === 'Maps' ? 'block' : 'none'
          }}>
            <li>
              <Link to="/redistricting-report-card">Redistricting Report Card</Link>
            </li>
            <li>
              <Link to="/redistricting-timeline-alert-map">Redistricting Timeline Alert</Link>
            </li>
            <li>
              <Link to="/reforms">Reforms</Link>
            </li>
            <li>
              <Link to="/tests">Gerrymandering tests (old)</Link>
            </li>
          </ul>
          </MenuItem>
        </Menu>
        <li>
          <Link to="/resources">Resources</Link>
        </li>
        <Menu
          onMouseLeave={() => setDropdownItemOpen(null)}>
          <MenuItem onHover={() => setDropdownItemOpen('States')}> 
            <a>States {dropdownItemOpen === 'States' ? '▲' : '▼'}</a>
          
          <ul 
            className="nav-menu-dropdown-item"
            style={{
            display: dropdownItemOpen === 'States' ? 'block' : 'none'
          }}>
            {
              Object.keys(statenames).map((statename) => {
                return <li key={statename} value={statename}>
                  <Link to={`/reforms/${statename}`}>
                    {statenames[statename]}
                  </Link>
                </li>
              })
            }
          </ul>
          </MenuItem>
        </Menu>
        
        <li>|</li>
        <li>
          <OutboundLink target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLScx9z7g6Kd7wkTYNtsD59XmxFN6ZdRpVa2bQx2UR6XrZiQmJA/viewform?usp=sf_link">
            Submit Feedback
          </OutboundLink>
        </li>
        <Menu
          onMouseLeave={() => setDropdownItemOpen(null)}>
          <MenuItem onHover={() => setDropdownItemOpen('Related Projects')}> 
            <a>Related Projects {dropdownItemOpen === 'Related Projects' ? '▲' : '▼'}</a>
          
          <ul 
            className="nav-menu-dropdown-item"
            style={{
            display: dropdownItemOpen === 'Related Projects' ? 'block' : 'none'
          }}>
            <li>
              <OutboundLink target="_blank" rel="noopener noreferrer" href="https://electoral-lab.org">
                Electoral Lab
              </OutboundLink>
            </li>
            <li>
              <OutboundLink target="_blank" rel="noopener noreferrer" href="https://representable.org">
                Representable
              </OutboundLink>
            </li>
            <li>
              <OutboundLink target="_blank" rel="noopener noreferrer" href="https://election.princeton.edu">
                PEC
              </OutboundLink>
            </li>
            <li>
              <OutboundLink target="_blank" rel="noopener noreferrer" href="https://openprecincts.org">
                OpenPrecincts
              </OutboundLink>
            </li>
          </ul>
          </MenuItem>
        </Menu>
      </ul>
      {/* <div className="header-bar-search">Search</div> */}
    </div>
  </div>
}

export default NavBar;
 
